import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Record } from '../../components/Record';
import { useParams } from "react-router-dom";
import { CostsForRoute } from './CostsForRoute';
import { Plan } from './Plan';

import {
	Button,
	Col,
	Row,
	Table,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from 'reactstrap';
import { QuoteSpec } from './QuoteSpec';
import log from 'loglevel';

export function Quote ()
{
	const kSpecPane = "spec";
	const kPlansPane = "plans";
	const kCostsPane = "costs";
	const kSalesPane = "sales";
	const kQbPane = "qb";
	const kIntegrationPane = "integration";

	const [ activePane, setActivePane ] = useState ( kSpecPane );
	const { quoteId } = useParams ();
	const history = useHistory();

	function QuoteNavLink ( props )
	{
		return <NavItem><NavLink className={activePane===props.tabName?"active":""} onClick={()=>setActivePane(props.tabName)}>{props.text}</NavLink></NavItem>;
	}

	function NavTabs ( props )
	{
		return <Nav tabs>
			<QuoteNavLink text="Spec" tabName={kSpecPane} />
			<QuoteNavLink text="Qty Breaks" tabName={kQbPane} />
			<QuoteNavLink text="Plans" tabName={kPlansPane} />
			<QuoteNavLink text="Costs" tabName={kCostsPane} />
			<QuoteNavLink text="Sales" tabName={kSalesPane} />
			<QuoteNavLink text="Integration" tabName={kIntegrationPane} />
		</Nav>;
	}

	function SpecTab ( props )
	{
		return <TabPane tabId={kSpecPane}>
			<QuoteSpec {...props} />
		</TabPane>;
	}

	function QbTab ( props )
	{
		return <TabPane tabId={kQbPane}>
			<Row>
			{
				props.qtyBreaks.breaks.map ( (qtyBreak)=> {

					var pricingRow = <tr><th>Pricing</th><td><p className="cursiveItalic">not set</p></td></tr>;
					if ( qtyBreak.markup !== undefined )
					{
						pricingRow = <tr><th>Markup</th><td>{qtyBreak.markup * 100.0}%</td></tr>;
					}
					else if ( qtyBreak.margin !== undefined )
					{
						pricingRow = <tr><th>Margin</th><td>{qtyBreak.margin * 100.0}%</td></tr>;
					}
					else if ( qtyBreak.price !== undefined )
					{
						pricingRow = <tr><th>Price</th><td>$ {qtyBreak.price}</td></tr>;
					}

					return <Col key={qtyBreak.qtyBreakId} xs="12" sm="12" md="12" lg="6" xl="4" className="cursiveQtyBreakTile">
						<h5>{qtyBreak.qty}</h5>
						<Table>
							<tbody>
								<tr><th>ID</th><td>{qtyBreak.qtyBreakId}</td></tr>
								{pricingRow}
								<tr><th>SKUs</th><td>{qtyBreak.skus}</td></tr>
								<tr><th>Plate Changes</th><td>{qtyBreak.plateChanges}</td></tr>
								<tr><th>Selected Route</th><td>{
									( qtyBreak.selectedRoute && qtyBreak.selectedRoute.length > 0 ) ?
										qtyBreak.selectedRoute :
										<p className="cursiveItalic">will not be quoted</p>
								}</td></tr>
							</tbody>
						</Table>
					</Col>
				} )
			}
			</Row>
		</TabPane>;
	}

	function PlansTab ( props )
	{
		var plans = [ ];
		if ( props.plans )
		{
			plans = Object.keys(props.plans).map ( (planName) =>
			{
				return <Plan key={planName} planName={planName} plan={props.plans[planName]}/>
			} )
		}

		return <TabPane tabId={kPlansPane}>
			{plans}
		</TabPane>;
	}

	function CostsTab ( props )
	{
		var routes = [ ];
		if ( props.costs.byRoute )
		{
			routes = Object.keys(props.costs.byRoute).map ( (routeName) => { return <div key={routeName}><CostsForRoute routeName={routeName} route={props.costs.byRoute[routeName]}/></div>} )
		}

		return <TabPane tabId={kCostsPane}>
			{routes}
		</TabPane>;
	}

	function SalesTab ( props )
	{
		var saleInfo = [ ];
		if ( props.job.sales && props.job.sales.history )
		{
			saleInfo = props.job.sales.history.map ( (saleRecord,index) => {

				var skus = saleRecord.skus.map ( (skuRecord) => {
					return <tr key={skuRecord.label}><td>{skuRecord.qty}</td><td>{skuRecord.label}</td></tr>;
				} );

				return <Table bordered key={saleRecord.id}>
					<tbody> 
						<tr><th>ID</th><td>{saleRecord.id}</td></tr>
						<tr><th>Date</th><td>{saleRecord.when}</td></tr>
						<tr><th>Qty</th><td>{saleRecord.qty}</td></tr>
						<tr><th>Qty Brk Id</th><td>{saleRecord.qtyBreakId}</td></tr>
						<tr><th>Route Width</th><td>{saleRecord.routeWidth}</td></tr>
						<tr><th>SKUs</th><td>
							<Table>
								<thead><tr><th>Qty</th><th>Label</th></tr></thead>
								<tbody>
								{skus}
								</tbody>
							</Table>
						</td></tr>
					</tbody>
				</Table>;
			} );
		}

		return <TabPane tabId={kSalesPane}>
			{saleInfo}
		</TabPane>;
	}

	function NetSuiteLink ( props )
	{
		if ( props.value )
		{
			return <span><a target="_blank" href={`/netsuite/${props.link}`}>{props.value}</a></span>
		}
		return <></>
	}

	const runSignal = async (accessToken) =>
	{
		try
		{
			const response = await fetch("/api/cpq/jobs/" + quoteId + "/diag/pushupdate", {
				method: 'POST',
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify ( {
				} )
			});
			const responseData = await response.json();
			alert("sent!");
		}
		catch ( error )
		{
			alert ( "problem sending signal: " + JSON.stringify ( error ) );
		}
	};

	/*
	 * Netsuite integration annotations: 

		{
			"netsuite": {
				"assemblyItem": 2951,
				"opportunityItem": 4756,
				"bom": 32,
				"netsuiteCustId": 845,
				"estimates": {
				"1.26": 4757
				},
				"manufacturingRouting": 2401,
				"bomRevision": 32,
				"salesOrder": 4857,

				"manufacturingRouting_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Barleywine": 2503,
				"assemblyItem_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Double Oaked": 3053,
				"bom_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Double Oaked": 35,
				"assemblyItem_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Hazelnut Cinnamon Coffee": 3054,
				"assemblyItem_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Base": 3051,
				"assemblyItem_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Barleywine": 3055,
				"assemblyItem_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Cocoanilla": 3052,
				"manufacturingRouting_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Double Oaked": 2502,
				"bomRevision_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Hazelnut Cinnamon Coffee": 36,
				"bom_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Base": 33,
				"manufacturingRouting_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Cocoanilla": 2601,
				"bom_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Hazelnut Cinnamon Coffee": 36,
				"bom_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Barleywine": 37,
				"bom_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Cocoanilla": 34,
				"bomRevision_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Base": 33,
				"bomRevision_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Cocoanilla": 34,
				"manufacturingRouting_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Base": 2501,
				"bomRevision_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Double Oaked": 35,
				"bomRevision_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Barleywine": 37,
				"manufacturingRouting_4a249424-db1f-4aa0-b4d5-c481e81b00c5_Family Tradition-Hazelnut Cinnamon Coffee": 2602
			}
		}
	*/


	function IntegrationTab ( props )
	{
		var netsuiteData = {};
		var annotationRows = [];
		if ( props.record && props.record.annotations && props.record.annotations.netsuite )
		{
			netsuiteData = props.record.annotations.netsuite;
			annotationRows = Object.keys ( props.record.annotations.netsuite ).map ( (entry)=> 
			{
				return <tr key={entry}><td>{entry}</td><td>{JSON.stringify(props.record.annotations.netsuite[entry])}</td></tr>;
			} );
		}

		return <TabPane tabId={kIntegrationPane}>
			<Row>
				<Col xs="12" sm="12" md="12" lg="6" xl="4" className="cursiveQtyBreakTile">
					<h5>NetSuite</h5>
					<Table>
						<tbody>
							<tr><th>Finished Good</th><td><NetSuiteLink link={"app/common/item/item.nl?id="+netsuiteData.assemblyItem} value={netsuiteData.assemblyItem} /></td></tr>
							<tr><th>Opportunity</th><td><NetSuiteLink link={"app/accounting/transactions/opprtnty.nl?id="+netsuiteData.opportunityItem} value={netsuiteData.opportunityItem} /></td></tr>
							<tr><th>Sales Order</th><td><NetSuiteLink link={"app/accounting/transactions/salesord.nl?id="+netsuiteData.salesOrder} value={netsuiteData.salesOrder} /></td></tr>
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col xs="12" sm="12" md="12" lg="6" xl="4" className="cursiveQtyBreakTile">
					<h5>Annotations</h5>
					<Table>
						<tbody>
							{annotationRows}
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col xs="12" sm="12" md="12" lg="6" xl="4" className="cursiveQtyBreakTile">
					<p>Use the Signal button to trigger NetSuite Relay with this quote.</p>
					<Button color="secondary" onClick={()=>{ runSignal(props.accessToken); }}>Signal</Button>
				</Col>
			</Row>
		</TabPane>;
	}

	const cloneQuote = async ( accessToken ) =>
	{
		try
		{
			const response = await fetch("/api/cpq/cloneJob", {
				method: 'POST',
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify ( {
					"source": quoteId
				} )
			});
			const responseData = await response.json();

			// this response is the whole new quote; just redirect to get the quote loaded
			var newQuoteId = responseData.id;
			history.push ( '/quotes/' + newQuoteId );
		}
		catch ( error )
		{
			alert ( "problem cloning: " + JSON.stringify ( error ) );
		}
	};

	function renderer ( record, accessToken )
	{
		return <>
			<Row>
				<Col><h2>{record.number} - {record.name}</h2></Col>
			</Row>
			<Row>
				<Col>
					<NavTabs accessToken={accessToken} />
					<TabContent activeTab={activePane}>
						<SpecTab job={record} spec={record.spec} quoteId={quoteId} customer={record.customer} accessToken={accessToken} cloneQuote={cloneQuote}/>
						<QbTab qtyBreaks={record.qtyBreaks} />
						<PlansTab plans={record.plans} />
						<CostsTab costs={record.costs} />
						<SalesTab job={record} />
						<IntegrationTab record={record} accessToken={accessToken} />
					</TabContent>
				</Col>
			</Row>
		</>;
	}

	log.info ( "quoteId: " + quoteId );
	return <Record
		siteUrl="/api/cpq"
		urlPath={`jobs/${quoteId}`}
		dataKey="job"
		renderer={renderer}
	/>;
}
