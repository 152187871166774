import React /*, { useState }*/ from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {
//  Collapse,
  Navbar,
//  NavbarToggler,
  NavbarBrand,
//  Nav,
//  NavItem,
//  NavLink,
} from 'reactstrap';

import { Logo } from '../CursiveLogo';

function renderUserInfo ( user, isAuthenticated, isLoading )
{
  if ( !isLoading && isAuthenticated )
  {
    return <span>{user.name}</span>
  }
  return <span />;
}

export function TopNav(props)
{
//  const [collapsed, setCollapsed] = useState(true);
  const { user, isAuthenticated, isLoading } = useAuth0();

//  const toggleNavbar = () => setCollapsed(!collapsed);

  const userInfo = renderUserInfo ( user, isAuthenticated, isLoading );

  return (
    <div>
      <Navbar color="faded" light>
        <NavbarBrand href="/" className="me-auto">
            <Logo />
        </NavbarBrand>

        {userInfo}

      </Navbar>
    </div>
  );
}

/*
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink href="/components/">Components</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://github.com/reactstrap/reactstrap">
                GitHub
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
*/
