import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";

export function Status(props)
{
  const { isAuthenticated, isLoading } = useAuth0();

  return <p style={{ fontFamily: "'Roboto Mono', sans-serif", fontSize:"0.8em" }}>
      Auth0 status: {isLoading ? "LOADING":"loaded"} / {isAuthenticated ? "logged in":"not logged in"}<br/>
    </p>
}
