import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import { Col, Row } from 'reactstrap';

export function Notes ( props )
{
	const [ noteText, setNoteText ] = useState ( "" );

    var notes = props.notes ? props.notes : [];

    var onTextChange = function ( e )
    {
        setNoteText ( e.target.value );
    }

    var onNoteSubmit = function ()
    {
        props.onNoteSubmit ( noteText );
    }

    var noteBlocks = notes.map ( ( note ) =>
    {
        return <div className="notesEntry" key={note.at}>
            <Row>
                <Col className="notesHeader" xs="12">{note.by}, {note.atText}:</Col>
            </Row>
            <Row>
                <Col xs="12">{note.note}</Col>
            </Row>
        </div>
    } );

    var inputArea = props.withAdd ?
        <Row>
            <Col xs="10">
                <Input type="textarea" name="text" id="exampleText" value={noteText} onChange={onTextChange} />
            </Col>
            <Col xs="2">
                <Button onClick={onNoteSubmit}>Save</Button>
            </Col>
        </Row> :
        <></>
    ;

    return <div className="notesBlock">
        <Row>
			<Col><h3>Notes</h3></Col>
		</Row>
        {noteBlocks}
        {inputArea}
    </div>;
}
