import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Record } from '../../../components/Record';
import { QtyInput } from '../../../components/QtyInput';
import { useParams } from "react-router-dom";

import {
	Button,
	Col,
	Row
} from 'reactstrap';
import log from 'loglevel';

const qtyUnitOptions = [
	{
	  label: "Meters",
	  value: "meters",
	},
	{
	  label: "M Impressions",
	  value: "mimps",
	},
];

export function TimerBar ( props )
{
	const [ qtyProvided, setQtyProvided ] = useState ( -1.0 );
	const [ qtyUnits, setQtyUnits ] = useState ( "mimps" );

	var resId = props.resId;
	var pendingWorkPackage = props.pendingWorkPackage;
	var lastTimer = props.lastTimer;
	var workPackage = props.workPackage;
	var laneCounts = props.laneCounts;

	function ActionButton ( buttonProps )
	{
		return <Button disabled={buttonProps.disabled} onClick={()=>{buttonProps.action()}}>{buttonProps.buttonText}</Button>;
	}

	function ActionAndQtyButton ( buttonProps )
	{
		return <Button disabled={qtyProvided<0.0} onClick={()=>{buttonProps.action( qtyProvided, qtyUnits )}}>{buttonProps.buttonText}</Button>;
	}

	function onUnitChange ( e )
	{
		setQtyUnits ( e.target.value );
	}

	//
	// we have a few different states to deal with:
	//
	//	1. There's no work package on the resource (and therefore should be no timer). In this case, the user can make a selection and 
	//		start a SETUP timer.  So, if we have a selection, offer SETUP.
	//
	//	2. There's a work package on the resource. This implies at least one SETUP timer. The most recent timer can be running or not, and can be
	//		a SETUP or WORK timer.
	//

	if ( workPackage )
	{
		// we have an active work package and presumably a timer reference as well
		if ( lastTimer === undefined )
		{
			log.warn ( "no lastTimer reference while workPackage is active." );
		}

		if ( lastTimer.type === "SETUP" && lastTimer.running )
		{
			// there's a running setup timer. Offer to pause or to switch to run.

			var pauseSetupButton = <ActionAndQtyButton action={props.stopTimer} buttonText="Pause Setup" />;
			var startRunButton = <ActionAndQtyButton action={props.startWork} buttonText="Start Run" />;

			return <Row>
				<Col>
					<QtyInput onQtyProvidedChange={setQtyProvided} /> <select value={qtyUnits} onChange={onUnitChange}>
						{qtyUnitOptions.map((option) => ( <option key={option.value} value={option.value}>{option.label}</option> ) )}
					</select> {pauseSetupButton} {startRunButton}
				</Col>
			</Row>
		}
		else if ( lastTimer.type === "SETUP" && !lastTimer.running )
		{
			// no running timer, so we can resume setup or switch to run

			var resumeSetupButton = <ActionButton action={props.startSetup} buttonText="Resume Setup" />;
			var startRunButton = <ActionButton action={props.startWork} buttonText="Start Run" />;
			var resetWorkButton = <ActionButton action={props.clearGang} buttonText="Reset" />;

			return <Row>
				<Col>
					{resumeSetupButton} {startRunButton} {resetWorkButton}
				</Col>
			</Row>
		}
		else if ( lastTimer.type === "WORK" && !lastTimer.running )
		{
			// no running timer, so we can resume work or reset

			var resumeSetupButton = <ActionButton action={props.startWork} buttonText="Resume Run" />;
			var resetWorkButton = <ActionButton action={props.clearGang} buttonText="Reset" />;

			return <Row>
				<Col>
					{resumeSetupButton} {resetWorkButton}
				</Col>
			</Row>
		}
		else
		{
			// we have a running work timer, so we can stop it

			var pauseWorkButton = <ActionAndQtyButton action={props.stopTimer} buttonText="Stop" />;

			return <Row>
				<Col>
					<QtyInput onQtyProvidedChange={setQtyProvided} /> <select value={qtyUnits} onChange={onUnitChange}>
						{qtyUnitOptions.map((option) => ( <option key={option.value} value={option.value}>{option.label}</option> ) )}
					</select> {pauseWorkButton}
				</Col>
			</Row>
		}
	}
	else
	{
		if ( Object.keys(pendingWorkPackage).length > 0 )
		{
			var setupOk = true;
			Object.keys(pendingWorkPackage).forEach ( (key) => {
				var laneCount = laneCounts[ key ];
				if ( laneCount === undefined || laneCount < 1 )
				{
					setupOk = false;
				}
			} );

			// there's a selection
			return <Row>
				<Col><ActionButton action={props.startSetup} buttonText="Start Setup" disabled={!setupOk} /></Col>
			</Row>
		}
		else
		{
			log.info ( "TimerBar: no package, no selection (" + props.updateCounter + ", " + JSON.stringify(Object.keys(pendingWorkPackage)));
			return <></>;
		}
	}
}
