
import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import {
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import { withRouter } from 'react-router-dom';

function logoutButton (isAuthenticated,style,logout)
{
    return isAuthenticated ?
        <NavItem>
            <NavLink className={style} href="#" onClick={() => logout({ returnTo: window.location.origin })}>Logout</NavLink>
        </NavItem> :
        <div />
    ;
}

export function SideBar ()
{
    var classNames = require('classnames');
    var linkStyle = classNames ( {
        cursiveNavLink: true
    } );

    const { isAuthenticated, logout } = useAuth0();

    return <div>
        <Nav vertical>
            <NavItem>
                <NavLink className={linkStyle} href="/customers">Customers</NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={linkStyle} href="/quotes">Quotes</NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={linkStyle} href="/schedules">Schedules</NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={linkStyle} href="/inks">Inks</NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={linkStyle} href="/materials">Materials</NavLink>
            </NavItem>
            {logoutButton(isAuthenticated,linkStyle,logout)}
        </Nav>
    </div>;
}
