import React, { useState, useEffect } from 'react';
import log from 'loglevel';

const format = function (n)
{
	return (~~n).toString().padStart(2, '0')
}

const duration = function (seconds)
{
	return [
		format(seconds / 60 / 60),
		format(seconds / 60 % 60),
		format(seconds % 60)
	].join(':');
}

const timeRender = function ( seconds )
{
	return duration ( seconds );
};

export function TimerReport ( props )
{
	// props.time: starting time
	// props.isActive: is the timer currently running?

	const [ timeNow, setTimeNow ] = useState ( props.time + props.elapsedSec );

	useEffect ( () => {
		const timer = setInterval ( () => { if ( props.isActive ) { setTimeNow ( timeNow + 1 ); } }, 1000);
	
		return () => {
		  clearInterval(timer);
		};
	  });

	return <span className={props.isActive?"cursiveActiveTimer":""}>{timeRender(timeNow)}</span>;
}
