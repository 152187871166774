import React from 'react';

import { RecordList } from '../../components/RecordList';

export function Materials ()
{
	function rowRenderer ( rec )
	{
		return <tr key={rec.id}>
			<td><a href={`/materials/${rec.id}`}>{rec.id}</a></td>
			<td>{rec.description}</td>
		</tr>
	};

	return <RecordList 
		siteUrl="/api/resources"
		urlPath="materials"
		listTitle = "Materials"
		listDataKey="materials"
		colHeaders={["ID","Description"]}
		rowRenderer={rowRenderer}
	></RecordList>
}
