import React from 'react';
import { Record } from '../../components/Record';
import { useParams } from "react-router-dom";

import {
	Col,
	Row,
	Table,
} from 'reactstrap';

export function Ink ()
{
	const { inkId } = useParams ();

	function renderer ( record )
	{
		return <>
			<Row>
				<Col>
					<h2>{record.id}: {record.description}</h2>
					<Table>
						<tbody>
							<tr><td>Ink Type</td><td>{record.inkType}</td></tr>
							<tr><td>Job Type</td><td>{record.jobType}</td></tr>
							<tr><td>Group ID</td><td>{record.cpqGroupId}</td></tr>
							<tr><td>Group</td><td>{record.cpqGroup}</td></tr>
							<tr><td>Systems</td><td>{record.systems}</td></tr>
							<tr><td>Cost</td><td>{record.cost}</td></tr>
							<tr><td>MSI/lb</td><td>{record.msiPerLb}</td></tr>
							<tr><td>Min Charge</td><td>{record.minCharge}</td></tr>
							<tr><td>Appearance</td><td>{record.inkAppearanceId}: {record.inkAppearance}</td></tr>
						</tbody>
					</Table>
				</Col>
			</Row>
		</>;
	}

	return <Record
		siteUrl="/api/resources"
		urlPath={`inks/${inkId}`}
		dataKey="ink"
		renderer={renderer}
	/>;
}
