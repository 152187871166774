import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import log from 'loglevel';

export function Record ( props )
{
	const [ record, setRecord] = useState({ data: {}, error: "(not run yet)", fetched:"" });
	const { getAccessTokenSilently, getTokenWithPopup } = useAuth0();

    const siteUrl = props.siteUrl;
    const urlPath = props.urlPath;
	const renderer = props.renderer;
	const dataKey = props.dataKey;

	const retrieveRecord = async () =>
	{
		try
		{
			const token = await getAccessTokenSilently ();

			log.info ( "fetching from " + siteUrl + "/" + urlPath );
			const response = await fetch(`${siteUrl}/${urlPath}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const responseData = await response.json();
//			log.info ( "response: " + JSON.stringify ( responseData ) );

			setRecord (
				{
					...record,
					data: response.ok ? responseData[dataKey] : {},
					fetched: urlPath,
					token: token,
					error: response.ok ? "" : response.statusText,
					status: response.status
				}
			);
		}
		catch ( error )
		{
			log.warn ( "Error: " + error );
			setRecord ( { ...record, data:{}, fetched: urlPath, error: error.error } );
		}
	};

	if ( record.fetched !== urlPath )
	{
		retrieveRecord ();
		return <div></div>;
	}
	else
	{
		log.info ( "record: data from " + siteUrl + "/" + urlPath + " already fetched." );
	}

	return renderer ( record.data, record.token, record.status );
}
