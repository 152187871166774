import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {
	Button
} from 'reactstrap';


export function Login ()
{
	const { loginWithRedirect } = useAuth0();

	return (
        <div>
			<Button color="primary" onClick={() => loginWithRedirect()}>Log In</Button>
		</div>
	);
}
