import React, { useState } from 'react';

export function SearchBox ( props )
{
    const smoothingMs = 300;

    const searchWas = props.searchWas ? props.searchWas : "";
	const [ searchText, setSearchText ] = useState(searchWas);
    const [ lastTimer, setLastTimer ] = useState ( -1 );

    function onChange ( e )
    {
        // set the local value
        var newValue = e.target.value;
        setSearchText ( newValue );

        // set/reset a timer to run a search
        if ( lastTimer > 0 )
        {
            clearTimeout ( lastTimer );
        }

        var tt = setTimeout ( () => {
            props.setSearchText ( newValue );
        }, smoothingMs );
        setLastTimer ( tt );
    }

    return <span>
        <input value={searchText} onChange={onChange} />
    </span>;
}
