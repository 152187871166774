import React from 'react';

import { CaretLeft, CaretLeftFill, CaretRight, CaretRightFill, SkipEnd, SkipEndFill, SkipStart, SkipStartFill } from 'react-bootstrap-icons';

export function Paginator ( props )
{
    // props.paging:
    //    { total: responseData.total, pages: responseData.pages, onPage: responseData.onPage, pg: responseData.pg, pgsz: responseData.pgsz },

    const firstPage = function()
    {
        props.updatePaging({pg: 1});
    }

    const prevPage = function()
    {
        props.updatePaging({pg: Math.max(props.paging.pg - 1,1)});
    }

    const nextPage = function()
    {
        props.updatePaging({pg: Math.min(props.paging.pg + 1,props.paging.pages)});
    }

    const lastPage = function()
    {
        props.updatePaging({pg: props.paging.pages});
    }

    if ( props.paging === null ) return <div></div>;
    if ( props.paging.total === undefined ) return <div></div>;
    if ( props.paging.total == 0 ) return <div>No records.</div>;

    const firstRecord = ( props.paging.pg - 1 ) * props.paging.pgsz + 1;
    const lastRecord = Math.min ( firstRecord + props.paging.onPage - 1, props.paging.total );
    const canFirstPage = firstRecord != 1;
    const canPrevPage = props.paging.pg != 1;
    const canNextPage = props.paging.pg < props.paging.pages;
    const canLastPage = props.paging.pg != props.paging.pages;

    return <div className="d-flex justify-content-end align-items-baseline">
        { canFirstPage ?
            <div className="cursivePaginatorIcon"><a onClick={firstPage}><SkipStartFill/></a></div> :
            <div className="cursivePaginatorIcon"><SkipStart/></div>
        }
        { canPrevPage ?
            <div className="cursivePaginatorIcon"><a onClick={prevPage}><CaretLeftFill/></a></div> :
            <div className="cursivePaginatorIcon"><CaretLeft/></div>
        }
        <div>{firstRecord}-{lastRecord} of {props.paging.total}</div>
        { canNextPage ?
            <div className="cursivePaginatorIcon"><a onClick={nextPage}><CaretRightFill/></a></div> :
            <div className="cursivePaginatorIcon"><CaretRight/></div>
        }
        { canLastPage ?
            <div className="cursivePaginatorIcon"><a onClick={lastPage}><SkipEndFill/></a></div> :
            <div className="cursivePaginatorIcon"><SkipEnd/></div>
        }
    </div>;
}
