import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Paginator } from '../Paginator';

import {
	Col,
	Row,
	Table
} from 'reactstrap';
import log from 'loglevel';
import { SearchBox } from '../SearchBox';

export function RecordList ( props )
{
	const [ recordList, setRecordList] = useState({ list: [], search: null, paging: {total:0}, error: "(not run yet)", fetched:false });
	const { getAccessTokenSilently, getTokenWithPopup } = useAuth0();

    const siteUrl = props.siteUrl;
    const urlPath = props.urlPath;
    const listTitle = props.listTitle;
    const listDataKey = props.listDataKey;

	const colHeaders = props.colHeaders;
	const rowRenderer = props.rowRenderer;

	const updatePaging = function ( pageData )
	{
		setRecordList (
			{
				...recordList,
				paging: pageData,
				fetched: false,
				error: ""
			}
		);
	}

	const setSearchText = function ( text )
	{
		setRecordList (
			{
				...recordList,
				search: text,
				fetched: false,
				error: ""
			}
		);
	}

	const populateList = async () =>
	{
		try
		{
			var urlArgs = "pg=" + recordList.paging.pg;
			if ( props.withSearch && recordList.search && recordList.search !== "" )
			{
				urlArgs = urlArgs + "&search=" + recordList.search;
			}

			const token = await getAccessTokenSilently ();

			const response = await fetch(`${siteUrl}/${urlPath}?${urlArgs}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const responseData = await response.json();

			setRecordList (
				{
					...recordList,
					list: responseData[listDataKey],
					paging: { total: responseData.total, pages: responseData.pages, onPage: responseData.onPage, pg: responseData.pg, pgsz: responseData.pgsz },
					fetched: true,
					error: ""
				}
			);
		}
		catch ( error )
		{
			log.warn ( "Error: " + error );
			setRecordList ( { ...recordList, fetched: true, error: error.error } );
		}
	};

	if ( !recordList.fetched )
	{
		populateList ( setRecordList );
	}

	var colHeaderCells = colHeaders.map( (header,index) => <th key={index}>{header}</th>);

	return (
		<div>
			<Row>
				<Col><h2>{listTitle}</h2></Col>
				<Col><Paginator paging={recordList.paging} updatePaging={updatePaging}></Paginator></Col>
			</Row>

			{
				props.withSearch ? 
				<SearchBox searchWas={recordList.search} setSearchText={setSearchText} /> :
				<></>
			}

			<Table striped bordered hover>
				<thead>
					<tr>
						{colHeaderCells}
					</tr>
				</thead>
				<tbody>
				{
					recordList.list.map((rec) => rowRenderer ( rec ))
				}
				</tbody>
			</Table>
		</div>
	);
}
