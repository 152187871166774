import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { TimerBar } from './TimerBar';
import { MaterialList } from './MaterialList';
import { useAuth0 } from "@auth0/auth0-react";

import {
	Input,
	Col,
	Row,
	Table
} from 'reactstrap';
import log from 'loglevel';
import { TimerReport } from '../../components/TimerReport';
import { QtyInput } from '../../components/QtyInput';
import { Notes } from '../../components/Notes';

const siteUrl = "/api/schedule";

export function Schedule ()
{
	const { getAccessTokenSilently, getTokenWithPopup } = useAuth0();

	const { resId } = useParams ();
	const [ resourceData, setResourceData] = useState({ data: {}, error: "(not run yet)", fetched:"" });

	const [ pendingWorkPackage, setPendingWorkPackage ] = useState ( {} );
	const [ laneCounts, setLaneCounts ] = useState ( {} );
	const [ updateCounter, setUpdateCounter ] = useState ( 0 );
	const [ showCompleted, setShowCompleted ] = useState ( false );

	const resourceUrlPath = "resources/" + resId + "?update=" + updateCounter;

	//
	//	pull the resource's schedule, work package, and last timer
	//
	const pullResourceSchedule = async () =>
	{
		try
		{
			const token = await getAccessTokenSilently ();

			log.info ( "fetching from " + siteUrl + "/" + resourceUrlPath );
			const response = await fetch(`${siteUrl}/${resourceUrlPath}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const responseData = await response.json();
//			log.info ( "response: " + JSON.stringify ( responseData ) );

			setResourceData (
				{
					...resourceData,
					data: response.ok ? responseData["resource"] : {},
					fetched: resourceUrlPath,
					token: token,
					error: response.ok ? "" : response.statusText,
					status: response.status
				}
			);

			// setup lane count data
			var newLaneCounts = {};
			responseData["resource"].schedule.map ( (schedEntry) => {
				var workOrder = schedEntry.workOrder;
				var opNumber = schedEntry.opNumber;
				newLaneCounts [ workOrder + "/" + opNumber ] = schedEntry.defaultLaneCount ? schedEntry.defaultLaneCount : -1;
			} );
			setLaneCounts ( newLaneCounts );
		}
		catch ( error )
		{
			log.warn ( "Error: " + error );
			setResourceData ( { ...resourceData, data:{}, fetched: resourceUrlPath, error: error.error } );
		}
	};

	// pull the data if we don't have it yet and return an empty page
	if ( resourceData.fetched !== resourceUrlPath )
	{
		pullResourceSchedule ();
		return <div>...</div>;
	}

	//
	//	post a timer related change to the API
	//
	const timerUpdate = async ( opLabel, body ) =>
	{
		var opLabelEnc = encodeURIComponent ( opLabel );
		var urlPath=`timers/${resId}/${opLabelEnc}`;

		try
		{
			const token = await getAccessTokenSilently ();

			log.info ( "updating timer data to " + siteUrl + "/" + urlPath );
			const response = await fetch(`${siteUrl}/${urlPath}`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify ( body === undefined ? {} : body )
			});
			const responseData = await response.json();

			// now reset for a new render
			setUpdateCounter ( updateCounter + 1 );
		}
		catch ( error )
		{
			log.warn ( "Error: " + error );
		}
	};

	//
	//	post a timer related change to the API
	//
	const clearGangOnServer = async ( ) =>
	{
		var urlPath= "resources/"+ resId + "/currentWorkPackage";

		try
		{
			const token = await getAccessTokenSilently ();

			log.info ( "clearing gang at " + siteUrl + "/" + urlPath );
			const response = await fetch(`${siteUrl}/${urlPath}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			const responseData = await response.json();

			// now reset for a new render
			setUpdateCounter ( updateCounter + 1 );
		}
		catch ( error )
		{
			log.warn ( "Error: " + error );
		}
	};

	function entryToSelectionKey ( e )
	{
		return e.workOrder + "/" + e.opNumber;
	}

	function clearSelection ()
	{
		setPendingWorkPackage ( {} );
	}

	function startSetup ( )
	{
		var payload =
		{
			operations:
			[
			]
		};

		Object.keys ( pendingWorkPackage ).forEach( ( key, index ) => 
		{
			payload.operations.push ( {
				workOrder: pendingWorkPackage[key].workOrder,
				operationNumber: pendingWorkPackage[key].opNumber,
				lanes: getLaneCount ( pendingWorkPackage[key].workOrder, pendingWorkPackage[key].opNumber )
			} );
		});

		timerUpdate ( "startSetup", payload );
	}

	function startWork ( )
	{
		timerUpdate ( "startWork" );
	}

	function stopTimer ( qtyProduced, qtyUnits )
	{
		timerUpdate ( "stopTimer", {qtyProduced: qtyProduced, qtyUnits: qtyUnits} );
		clearSelection ();
	}

	function clearGang ( qtyProduced )
	{
		clearGangOnServer ();
		clearSelection ();
	}

	function isInPendingWorkPackage ( schedEntry )
	{
		const key = entryToSelectionKey ( schedEntry );
		return pendingWorkPackage.hasOwnProperty ( key );
	}

	function addToPendingWorkPackage ( schedEntry )
	{
		const key = entryToSelectionKey ( schedEntry );
		const newJobSet = JSON.parse(JSON.stringify(pendingWorkPackage));
		newJobSet [ key ] = schedEntry;
		setPendingWorkPackage ( newJobSet );
	}

	function removeFromPendingWorkPackage ( schedEntry )
	{
		const key = entryToSelectionKey ( schedEntry );
		let {[key]: _, ...rest} = pendingWorkPackage;
		setPendingWorkPackage ( rest );
	}

	//
	//	Handle a click on a schedule entry. We only manipulate selection when we 
	//  don't have a work package on the system.
	//
	function onRowClick ( schedEntry )
	{
		if ( isInPendingWorkPackage ( schedEntry ) )
		{
			removeFromPendingWorkPackage ( schedEntry );
		}
		else
		{
			addToPendingWorkPackage ( schedEntry );
		}
	}

	function isInWorkPackage ( workPackage, schedEntry )
	{
		if ( !workPackage ) return false;

		for ( const wpEntry of workPackage.workOrderOperations )
		{
			if ( wpEntry.workOrder === schedEntry.workOrder && wpEntry.opNumber === schedEntry.opNumber )
			{
				return true;
			}
		}
		return false;
	}

	function isEntryCompleted ( schedEntry )
	{
		return schedEntry.workOrderDetail.totalQtyProduced >= schedEntry.quantity; 
	}

	function selectHiliteForRow ( schedEntry, runningTimer, workPackage )
	{
		// the work order is considered complete once the qty produced meets the qty requested
		if ( isEntryCompleted ( schedEntry ) )
		{
			return "cursiveCompletedEntryRow";
		}

		// if there's a work package, that dictates selection
		if ( workPackage )
		{
			if ( isInWorkPackage ( workPackage, schedEntry ) )
			{
				return "cursiveHiliteRow";
			}
			else
			{
				return "";
			}
		}

		// not sure this section is still relevant...
		// is this entry being worked (including setup)
		var isRunning = ( schedEntry.workOrderDetail.setupIsRunning || schedEntry.workOrderDetail.workIsRunning );
		if ( isRunning ) return "cursiveHiliteRow";

		// is this entry part of the current selection?
		var isSelected = pendingWorkPackage.hasOwnProperty ( entryToSelectionKey ( schedEntry ) );
		if ( isSelected ) return "cursiveHiliteRow";

		// otherwise no highlight
		return "";
	}

	function getLaneCount ( workOrder, opNumber )
	{
		return laneCounts [ workOrder + "/" + opNumber ];
	}

	function noteLaneCount ( workOrder, opNumber, qty )
	{
		log.info ( workOrder + " " + opNumber + ": " + qty );

		const key = workOrder + "/" + opNumber;
		const newLaneCounts = JSON.parse(JSON.stringify(laneCounts));
		newLaneCounts [ key ] = qty;
		setLaneCounts ( newLaneCounts );
	}

	const onNoteSubmit = async ( note ) =>
	{
		log.info ( "new note: " + note );

		var urlPath=`resources/${resId}/currentWorkPackage/notes`;

		try
		{
			const token = await getAccessTokenSilently ();

			log.info ( "posting note to " + siteUrl + "/" + urlPath );
			const response = await fetch(`${siteUrl}/${urlPath}`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify ( { note: note } )
			});
			const responseData = await response.json();

			// now reset for a new render
			setUpdateCounter ( updateCounter + 1 );
		}
		catch ( error )
		{
			log.warn ( "Error: " + error );
		}
	}

	// render the schedule, etc.

	var runningTimer = ( resourceData.data.timer && resourceData.data.timer.running );
	var timerElapsedSec = runningTimer ? resourceData.data.timer.elapsedMs / 1000 : 0;
	var workPackage = resourceData.data.workPackage;

	var needLaneCount = workPackage === undefined;
	var needSelector = needLaneCount;

	var rows = resourceData.data.schedule.map ( (schedEntry) => {

		var skipIt = workPackage && !isInWorkPackage ( workPackage, schedEntry );
		if ( skipIt ) return null;

		var isSelected = pendingWorkPackage.hasOwnProperty ( entryToSelectionKey ( schedEntry ) );
		var hilite = selectHiliteForRow ( schedEntry, runningTimer, workPackage );
		var isCompleted = isEntryCompleted ( schedEntry );
		if ( isCompleted && !showCompleted )
		{
			return null;
		}

		var initLaneCount = getLaneCount(schedEntry.workOrder,schedEntry.opNumber);
		var thisRowNeedsLaneCount = pendingWorkPackage.hasOwnProperty ( entryToSelectionKey ( schedEntry ) );

		var rowNeedsSelector = needSelector; 
		var checkboxCell = rowNeedsSelector ?
			<td><input type="checkbox" checked={isSelected} onChange={()=>onRowClick(schedEntry)} ></input></td> :
			( needSelector ? <td></td> : <></> )
		;

		var laneCell = <></>;
		if ( needLaneCount )
		{
			if ( thisRowNeedsLaneCount )
			{
				laneCell = <td><QtyInput initVal={initLaneCount} onQtyProvidedChange={noteLaneCount.bind(this,schedEntry.workOrder,schedEntry.opNumber)}/></td>;
			}
			else
			{
				laneCell = <td></td>
			}
		}

		var setupTimerElapsedSec = schedEntry.workOrderDetail.setupIsRunning ? timerElapsedSec : 0;
		var workTimerElapsedSec = schedEntry.workOrderDetail.workIsRunning ? timerElapsedSec : 0;
	
		return <tr className={hilite} key={schedEntry.setupStart} >
			{checkboxCell}
			<td>{schedEntry.workOrder}</td>
			<td>{schedEntry.partNumber}</td>
			<td>{schedEntry.customer}</td>
			<td>{schedEntry.quantity}</td>
			<td>{schedEntry.workOrderDetail.totalQtyProduced}</td>
			<td><TimerReport time={schedEntry.workOrderDetail.totalSetupTimeSec} isActive={schedEntry.workOrderDetail.setupIsRunning} elapsedSec={setupTimerElapsedSec} /></td>
			<td><TimerReport time={schedEntry.workOrderDetail.totalRunTimeSec} isActive={schedEntry.workOrderDetail.workIsRunning} elapsedSec={workTimerElapsedSec}/></td>
			{ laneCell }
		</tr>
	} );

	var noteList = [];
	var matlList = [];
	var seenNotes = {};
	var seenMatls = {};
	Object.keys ( pendingWorkPackage ).map ( (selectedWoo) =>
	{
		var schedEntry = pendingWorkPackage [ selectedWoo ];

		// notes...
		schedEntry.notes.map ( (note) => {
			if ( !seenNotes.hasOwnProperty ( "" + note.at ) )
			{
				noteList.push ( note );
				seenNotes[ "" + note.at] = {};
			}
		} );

		// materials...
		Object.keys(schedEntry.materials).map ( (matlId) =>
		{
			if ( !seenMatls.hasOwnProperty ( matlId ) )
			{
				var desc = schedEntry.materials[matlId];
				matlList.push ( desc );
				seenMatls[matlId] = {};
			}
		} );
	} );

	function onChangeShowCompleted () 
	{
		setShowCompleted ( !showCompleted );
	}

	return <>
		<Row>
			<Col><h2>{resId}</h2></Col>
		</Row>
		<Row className="cursiveControlRow">
			<Col><label><input type="checkbox" checked={showCompleted} onChange={onChangeShowCompleted} ></input> Show Completed</label></Col>
		</Row>
		<Row>
			<Col>
				<Table>
					<tbody>
						<tr>
							{ needSelector ? <th>&nbsp;</th> : <></> }
							<th>Job Number</th>
							<th>Part</th>
							<th>Customer</th>
							<th>Qty</th>
							<th>Produced</th>
							<th>Setup</th>
							<th>Run</th>
							{ needLaneCount ? <th>Lanes</th> : <></> }
						</tr>
						{rows}
					</tbody>
				</Table>
			</Col>
		</Row>
		<TimerBar
			resId={resId}
			pendingWorkPackage={pendingWorkPackage}
			workPackage={resourceData.data.workPackage}
			lastTimer={resourceData.data.timer}
			startSetup={startSetup}
			startWork={startWork}
			stopTimer={stopTimer}
			clearGang={clearGang}
			updateCounter={updateCounter}
			laneCounts={laneCounts}
		/>
		<Row>
			<Col xs="12" sm="12" md="12" lg="6">
				<MaterialList
					materials={matlList}
				/>
			</Col>
			<Col xs="12" sm="12" md="12" lg="6">
				<Notes
					notes={resourceData.data.workPackage ? resourceData.data.workPackage.notes : noteList }
					onNoteSubmit={onNoteSubmit}
					withAdd={workPackage !== undefined}
				/>
			</Col>
		</Row>
	</>;
}
