import React from 'react';
import { Record } from '../../components/Record';
import log from 'loglevel';
import { useParams } from "react-router-dom";

import {
	Col,
	Row,
	Table
} from 'reactstrap';

export function Customer ()
{
	const { custId } = useParams ();

	function renderAddress ( title, addr )
	{
		if ( addr === undefined ) return <tr />;
		return <tr className="cursiveAddressEntry" key={`addr-${title}`}>
			<td>{title}</td>
			<td>
				<div>{addr.address1}</div>
				<div>{addr.address2}</div>
				<div>{addr.city} {addr.state} {addr.zip}</div>
			</td>
		</tr>;
	}

	function renderer ( record )
	{
		var contacts = [];
		if ( record.contacts )
		{
			contacts = record.contacts.map ( ( contact ) =>
			{
				return <tr key={contact.netsuiteId}>
					<td>{contact.netsuiteId}</td>
					<td>{contact.name}</td>
					<td>{contact.email}</td>
					<td>{contact.phone}</td>
				</tr>;
			} );
		}
		else
		{
			contacts.push ( <tr key="olderformat">
				<td></td>
				<td>{record.contactName}</td>
				<td>{record.contactEmail}</td>
				<td>{record.conactPhone}</td>
			</tr> );
		}

		return <>
			<Row>
				<Col><h2>{record.name}</h2></Col>
			</Row>
			<Row>
				<Col>
					<Table>
						<tbody>
							<tr><th>Cursive ID</th><td>{record.id}</td></tr>
							<tr><th>Default Margin</th><td>{record.defaultMargin}</td></tr>
							<tr><th>Terms</th><td>{record.terms}</td></tr>
							<tr><th>NetSuite Internal ID</th><td><a target="_blank" href={`/netsuite/app/common/entity/custjob.nl?id=${record.netsuiteId}`}>{record.netsuiteId}</a></td></tr>
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="cursiveAddressBlock">
						<h3>Contacts</h3>
						<table className="table">
							<thead>
								<tr>
									<th>ID</th><th>Name</th><th>Email</th><th>Phone</th>
								</tr>
							</thead>
							<tbody>
								{contacts}
							</tbody>
						</table>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="cursiveAddressBlock">
						<h3>Addressing</h3>
						<table className="table">
							<thead>
								<tr>
									<th>Name</th><th>Address</th>
								</tr>
							</thead>
							<tbody>
								{ renderAddress("Billing",record.billing) }
								{
									record && record.shipping ?
										record.shipping.map ( (addr) => renderAddress(addr.label,addr) ) :
										<div />
								}
							</tbody>
						</table>
					</div>
				</Col>
			</Row>
		</>;
	}

	return <Record
		siteUrl="/api/customers"
		urlPath={`customers/${custId}`}
		dataKey="customer"
		renderer={renderer}
	/>;
}
