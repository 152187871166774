import React, { useState } from 'react';
import log from 'loglevel';

// https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
function isNumeric(str)
{
	if (typeof str != "string") return false // we only process strings!  
	return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		   !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function QtyInput ( props )
{
	const [ qty, setQty ] = useState ( "" );

	const onUpdate = function ( e )
	{
		setQty ( e.target.value );
		if ( props.onQtyProvidedChange )
		{
			var isNum = e.target.value != "" &&
				isNumeric ( e.target.value )
			;
			var val = isNum ? parseFloat ( e.target.value ) : -1.0;
			if ( val >= 0.0 )
			{
				props.onQtyProvidedChange ( val );
			}
			else
			{
				props.onQtyProvidedChange ( -1.0 );
			}
		}
	};

	return <input onInput={onUpdate} value={qty}></input>;
}
