
import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Redirect } from 'react-router-dom';

export function PrivateRoute ( { component: Component, ...rest } )
{
    const { isAuthenticated } = useAuth0();

	return (
		<Route
			{...rest}
			render = {(props) =>
				{
					return isAuthenticated ?
						( <Component {...props} /> ) :
						( <Redirect to={{pathname:"/login", state:{referer:props.location} }} /> );
				}
			}
		/>
	);
}
