import React, { useState } from 'react';

import {
	Col,
	Row,
    Table,
} from 'reactstrap';

export function MaterialList ( props )
{
    var rows = props.materials.map ( (entry,index) => {
        return <tr key={index}><td>{entry}</td></tr>
    } );

    return <div className="notesBlock">
        <Row>
            <Col><h3>Materials</h3></Col>
        </Row>
        <Row>
            <Table>
                <tbody>
                {rows}
                </tbody>
            </Table>
        </Row>
    </div>;
}
