import React from 'react';

import {
    Button,
	Col,
	Row,
	Table,
} from 'reactstrap';

export function QuoteSpec ( props )
{
	function RecordValue ( props )
	{
		var val = props.value;
		if ( typeof props.value == 'boolean' )
		{
			val = props.value ? "true" : "false";
		}
		return <div>{props.label}: {val}</div>;
	}

	function RecordSizeValue ( props )
	{
		var val = props.value;
		if ( typeof props.value == 'object' )
		{
			if ( props.value.system === "IMPERIAL" )
			{
				val = props.value.units + "\"";
			}
			else if ( props.value.system === "METRIC" )
			{
				val = (props.value.units*10) + " mm";
			}
		}
		else
		{
			if ( !props.defUnits )
			{
				val = props.value + "\"";
			}
			else
			{
				val = props.value + " " + props.defUnits;
			}
		}
		return <RecordValue label={props.label} value={val} />;
	}

	function MatlValue ( props )
	{
		var val = props.value;
		return <div>{props.label}: <a href={`/materials/${props.value}`}>{val}</a></div>;
	}

    function buildMatlRows ( props )
    {
        var result = [];

        if ( props.spec.type === 'PSL' )
        {
            result.push ( <MatlValue label="Stock" value={props.spec.materials.quotedStock} /> );
        }
        else
        {
            result.push ( <MatlValue label="Film" value={props.spec.materials.quotedFilm} /> );
        }

        if ( props.spec.options.coldFoil )
        {
            result.push ( <MatlValue label="Foil" value={props.spec.materials.quotedFoil} /> );
        }

        if ( props.spec.type === 'PSL' )
        {
            if ( props.spec.options.laminated )
            {
                result.push ( <MatlValue label="Lam 1" value={props.spec.materials.quotedLam1} /> );
                result.push ( <MatlValue label="Lam 2" value={props.spec.materials.quotedLam2} /> );
            }

            if ( props.spec.options.hotStamp > 0 )
            {
                result.push ( <MatlValue label="Hotstamp 1" value={props.spec.materials.quotedHotstamp1} /> );
                if ( props.spec.hotStamp > 1 )
                {
                    result.push ( <MatlValue label="Hotstamp 2" value={props.spec.materials.quotedHotstamp2} /> );
                }
            }
        }

        return result;
    }

    var hasQuoteLines = props.job.quote && props.job.quote.lineItems && props.job.quote.lineItems.length > 0;
    var matlRows = buildMatlRows ( props );

    return <Row>
        <Col xs="12" sm="6" md="6" lg="4">
            <div className="cursiveRecordSection">
                <h3>Basics</h3>
                <RecordValue label="State" value={props.job.state} />
                <RecordValue label="Label Type" value={props.spec.type} />
                <RecordValue label="Order Type" value={props.job.orderType?props.job.orderType:"undefined"} />
                <div>Customer: <a href={"/customers/" + props.customer.customerId}>{props.customer.customerId}</a>: {props.customer.customerName}</div>
                <Button outline disabled={!hasQuoteLines} color="secondary" tag="a" target="_blank" href={`/api/cpq/jobs/${props.quoteId}/document?token=${props.accessToken}`}>PDF</Button>&nbsp;
                <Button outline color="secondary" onClick={()=>{ props.cloneQuote(props.accessToken,props.reload) }}>Clone</Button>
            </div>
        </Col>
        <Col xs="12" sm="6" md="6" lg="4">
            <div className="cursiveRecordSection">
                <h3>Dimensions</h3>
                {
                    props.spec.type === 'PSL' ?
                        <>
                            <RecordValue label="Shape" value={props.spec.dimensions.shape} />
                            <RecordSizeValue label="Size Across" value={props.spec.dimensions.sizeAcross} />
                            <RecordSizeValue label="Size Around" value={props.spec.dimensions.sizeAround} />
                            <RecordSizeValue label="Space Across" value={props.spec.dimensions.spaceAcross} />
                            <RecordSizeValue label="Space Around" value={props.spec.dimensions.spaceAround} />
                            <RecordSizeValue label="Corner Radius" value={props.spec.dimensions.cornerRadius} />
                        </> :
                        <>
                            <RecordSizeValue label="Layflat" value={props.spec.dimensions.layflat} defUnits="cm" />
                            <RecordSizeValue label="Repeat" value={props.spec.dimensions.repeat} defUnits="cm" />
                        </>
                }
            </div>
        </Col>
        <Col xs="12" sm="6" md="6" lg="4">
            <div className="cursiveRecordSection">
                <h3>Options</h3>
                {
                    props.spec.type === 'PSL' ?
                    <>
                            <RecordValue label="Cold Foil" value={props.spec.options.coldFoil} />
                            <RecordValue label="Core Size" value={props.spec.options.coreSize} />
                            <RecordValue label="Emboss" value={props.spec.options.emboss} />
                            <RecordValue label="Hot Stamp" value={props.spec.options.hotStamp} />
                            <RecordValue label="Label Appl Dir" value={props.spec.options.labelApplicationDirection} />
                            <RecordValue label="Laminated" value={props.spec.options.laminated} />
                            <RecordValue label="Screens" value={props.spec.options.numberOfScreens} />
                            <RecordValue label="Variable" value={props.spec.options.variableData} />
                        </> :
                        <>
                            <RecordValue label="Clear" value={props.spec.options.clear} />
                            <RecordValue label="Cold Foil" value={props.spec.options.coldFoil} />
                            <RecordValue label="Cut Pieces" value={props.spec.options.cutPieces} />
                            <RecordValue label="Perforation" value={props.spec.options.perforationType} />
                            <RecordValue label="Seam Location" value={props.spec.options.seamLocation} />
                        </>
                }
            </div>
        </Col>

        <Col xs="12" sm="6" md="6" lg="4">
            <div className="cursiveRecordSection">
                <h3>Materials</h3>
                {matlRows}
            </div>
        </Col>

        <Col xs="12" sm="6" md="6" lg="4">
            <div className="cursiveRecordSection">
                <h3>Inks</h3>
                <Table>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Coverage</th>
                            <th>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                                // no ink link because quote is storing by group ID rather than ink record ID
                                props.spec.inks.map ( ( ink ) => {
                                return <tr key={ink.inkEntryId}>
                                    <td>{ink.color}</td>
                                    <td>{ink.coverage}</td>
                                    <td>{ink.category}</td>
                                </tr>
                            } )
                        }
                    </tbody>
                </Table>
            </div>
        </Col>

        <Col xs="12" sm="6" md="6" lg="4">
            <div className="cursiveRecordSection">
                <h3>Default Charges</h3>
                <p style={{ fontFamily: "'Roboto Mono', sans-serif", fontSize:"0.8em" }}>{JSON.stringify(props.spec.defaultCharges)}</p>
            </div>
        </Col>
    </Row>;
}