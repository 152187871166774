import React, { useState } from 'react';
import log from 'loglevel';

export function Plan ( props )
{
	let planJson = props.plan;
	let planJsonStr = JSON.stringify ( planJson );

	// system customizations
	var sysList = planJson.systems.map ( ( system ) => {
		return <table className="table table-sm" key={system.op}>
			<tbody>
				<tr key="op"><td>Op</td><td>{system.op}</td></tr>
				<tr key="enable"><td>Enable?</td><td>{system.enableScript}</td></tr>
			</tbody>
		</table>
	} );

    return <div className="cursivePlanPanel" key={props.planName}>
		<h3>{planJson.displayName}</h3>
		<table className="table table-sm">
			<tbody>
				<tr key="id"><td>ID</td><td>{planJson.id}</td></tr>
				<tr key="enabled"><td>Enabled</td><td>{planJson.enabled ? "Yes" : "No"}</td></tr>
				<tr key="baseRef"><td>Based on Standard Plan</td><td>{planJson.basedOn}</td></tr>
			</tbody>
		</table>
		<div>
			{ sysList.length > 0 ? <h5>System Overrides</h5> : null }
			{sysList}
		</div>

		<div style={{fontSize: '0.7em'}}>{planJsonStr}</div>
    </div> 
}

