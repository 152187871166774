import React from 'react';
import { Record } from '../../components/Record';
import { useParams } from "react-router-dom";

import {
	Col,
	Row,
	Table,
} from 'reactstrap';

import { CheckCircle, Circle } from 'react-bootstrap-icons';

export function Material ()
{
	const { matlId } = useParams ();

	function Check ( props )
	{
		if ( props.applies )
		{
			return <CheckCircle />
		}
		return <Circle />;
	}

	function renderer ( record )
	{
		// {"shrink":false,"coldFoil":false,"lam2":false,"lam1":false,"hotstamp2":false,"stock":true,"hotstamp":false}

		return <>
			<Row>
				<Col>
					<h2>{record.id}: {record.description}</h2>

					<h4>Details</h4>
					<Table>
						<tbody>
							<tr><td>Face Stock</td><td>{record.faceStock}</td></tr>
							<tr><td>Adhesive</td><td>{record.adhesive}</td></tr>
							<tr><td>Liner</td><td>{record.liner}</td></tr>
							<tr><td>Cost</td><td>{record.cost}</td></tr>
							<tr><td>Status</td><td>{record.status}</td></tr>
						</tbody>
					</Table>

					<h4>Use</h4>
					<Table>
						<tbody>
							<tr><td><Check applies={record.stock} /> Stock</td></tr>
							<tr><td><Check applies={record.shrink} /> Shrink</td></tr>
							<tr><td><Check applies={record.coldFoil} /> Cold Foil</td></tr>
							<tr><td><Check applies={record.lam1} /> Lam 1</td></tr>
							<tr><td><Check applies={record.lam2} /> Lam 2</td></tr>
							<tr><td><Check applies={record.hotstamp1} /> Hotstamp 1</td></tr>
							<tr><td><Check applies={record.hotstamp2} /> Hotstamp 2</td></tr>
						</tbody>
					</Table>
				</Col>
			</Row>
		</>;
	}

	return <Record
		siteUrl="/api/resources"
		urlPath={`materials/${matlId}`}
		dataKey="material"
		renderer={renderer}
	/>;
}
