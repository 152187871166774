import React from 'react';

import { RecordList } from '../../components/RecordList';

export function Quotes ()
{
	function rowRenderer ( rec )
	{
		return <tr key={rec.id}>
			<td><a href={"/quotes/"+rec.id}>{rec.number}</a></td>
			<td>{rec.name}</td>
			<td><a href={"/customers/"+rec.customerId}>{rec.customerName}</a></td>
			<td>{rec.type}</td>
			<td>{rec.status}</td>
			<td>{rec.updated.whenText} by {rec.updated.by}</td>
		</tr>
	};

	return <RecordList 
		siteUrl="/api/cpq"
		urlPath="jobs"
		listTitle = "Quotes"
		listDataKey="jobs"
		withSearch="true"
		colHeaders={["Number","Name","Customer","Type","State","Updated"]}
		rowRenderer={rowRenderer}
	></RecordList>
}
