import React from 'react';
import { RecordList } from '../../components/RecordList';

export function Customers ()
{
	function rowRenderer ( rec )
	{
		var billingLine = <td>&nbsp;</td>;
		if ( rec.billing )
		{
			billingLine = <td>{rec.billing.city}, {rec.billing.state}</td>;
		}

		return <tr key={rec.id}>
			<td><a href={"/customers/"+rec.id}>{rec.id}</a></td>
			<td>{rec.name}</td>
			<td>{rec.contactPhone}</td>
			{billingLine}
			<td><a target="_blank" href={`/netsuite/app/common/entity/custjob.nl?id=${rec.netsuiteId}`}>{rec.netsuiteId}</a></td>
		</tr>
	};

	return <RecordList 
		siteUrl="/api/customers"
		urlPath="customers"
		listTitle = "Customers"
		listDataKey="customers"
		colHeaders={["ID","Name","Phone","Locale","NetSuite"]}
		rowRenderer={rowRenderer}
	></RecordList>
}
