import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useAuth0 } from "@auth0/auth0-react";

import { RecordList } from '../../components/RecordList';

export function Schedules ()
{
	var [ downloading, setDownloading ] = useState ( false );
	const { getAccessTokenSilently } = useAuth0();

	const downloadCsvReport = async () =>
	{
		setDownloading ( true );

		const token = await getAccessTokenSilently ();

		fetch ( "/api/schedule/report/workpkg",
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then ( response => {
				if (response.ok)
				{
					return response.blob();
				}
				else
				{
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
			})
			.then ( blob =>
			{
				// Create a temporary URL for the Blob
				const url = URL.createObjectURL(blob);

				// Create a link element
				const link = document.createElement('a');
				link.href = url;
				link.download = 'scheduleReport.csv';

				// Programmatically click the link to start the download
				document.body.appendChild(link);
				link.click();

				// Clean up the temporary URL and link
				URL.revokeObjectURL(url);
				document.body.removeChild(link);

				setDownloading ( false );
			} )
			.catch ( error => {
				alert ( "There was a problem downloading the report." );
				setDownloading ( false );
			} );
	}

	function rowRenderer ( rec )
	{
		var resId = JSON.stringify ( rec );
		return <tr key={resId}>
			<td><a href={"/schedules/"+rec}>{rec}</a></td>
		</tr>
	};

	return <><RecordList 
		siteUrl="/api/schedule"
		urlPath="resources"
		listTitle = "Schedules"
		listDataKey="resources"
		colHeaders={["Resource"]}
		rowRenderer={rowRenderer}
	></RecordList>
	<Button onClick={downloadCsvReport} disabled={downloading}>Report</Button>
	</>
}
