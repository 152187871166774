import React from 'react';

import { RecordList } from '../../components/RecordList';

export function Inks ()
{
	function rowRenderer ( rec )
	{
		return <tr key={rec.id}>
			<td><a href={"/inks/" + rec.id}>{rec.id}</a></td>
			<td>{rec.description}</td>
			<td>{rec.cpqGroupId}</td>
		</tr>
	};

	return <RecordList 
		siteUrl="/api/resources"
		urlPath="inks"
		listTitle = "Inks"
		listDataKey="inks"
		colHeaders={["ID","Description","CPQ Group ID"]}
		rowRenderer={rowRenderer}
	></RecordList>
}
